import { Button } from "react-bootstrap";
import { Arrowrightw } from "../../icons";
import { SPEEDSIZE_PREFIX } from "../../App";

function TopGame({ banner }) {
  const gameUrl = `https://m.apuestas.codere.es/deportesEs/#/casino/${
    banner.gameType
  }Page?lobby=Nacional&playgame=${encodeURIComponent(
    banner.iniciator + " " + banner.name
  )}`;
  return (
    <div className="topGame">
      <div className="card">
        <a href={gameUrl} rel={"nofollow"} className="topGameImageWrapper">
          <img
            width="16"
            height="5"
            className="game_logo"
            src={`${SPEEDSIZE_PREFIX}/${banner.logo}`}
            alt={`${banner.name}-logo`}
          />
          <img
            width="18"
            height="10"
            className="topGameImage"
            src={`${SPEEDSIZE_PREFIX}/${banner.image}`}
            alt={banner.name}
          />
        </a>
        <div className="game_info">
          <h3 className="topGameTitle">{banner.name}</h3>
          <div className="topGameSponsor">{banner.sponsor}</div>
        </div>
        <div className="jugar-div">
          <Button
            className="jugar-regis d-flex justify-content-around align-items-center"
            size="lg"
            href={gameUrl}
          >
            <span>Juega Ahora</span>
            <div style={{ margin: "2px 0 0 0" }}>
              <Arrowrightw />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TopGame;
